// config.js

const bizIcon = "https://theexpertsheatingcooling.com/wp-content/uploads/2024/10/the-experts-heating-logo.webp";
const imageOne = "https://theexpertsheatingcooling.com/wp-content/uploads/2024/10/3.avif";
const imageTwo = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/Riio4KsXUEKMMxd4cgwa/media/670c6c2a2af3600eb79233fd.jpeg";
const imageThree = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/Riio4KsXUEKMMxd4cgwa/media/670c6c458a100401c9964a56.jpeg";
const imageFour = "https://images.pexels.com/photos/3768910/pexels-photo-3768910.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
const imageFive = "https://theexpertsheatingcooling.com/wp-content/uploads/2024/10/ac.gif";

const socialMediaLink = "https://theexpertsheatingcooling.com/";

export const siteConfig = {
  title: "The Experts | Heating & Cooling",
  metaDescription: "The Experts | Heating & Cooling",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-green-500"
  },
  logo: {
    src: bizIcon,
    alt: "The Experts | Heating & Cooling",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "The Experts | Heating & Cooling",
    headline: "HVAC that you can depend on!",
    description: "We are an owner-operated company dedicated to meeting the air and heating needs of both residential and commercial clients. Our goal is to create comfortable, efficient living and working spaces that enhance the well-being of our customers.",
  },
  images: {
    business: {
      src: imageOne,
      alt: "Making Homes Shine, One Clean at a Time",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      name: 'Reliable HVAC Solutions for Your Home or Business',
      description: 'Our skilled HVAC technicians provide fast, efficient, and affordable service, from emergency repairs to routine maintenance. Available 24/7, we ensure your systems run smoothly year-round.',
    },
    {
      name: 'Emergency 24/7 Support',
      description: 'Whether it’s an HVAC emergency or a last-minute cleaning request, our team is available around the clock to provide the support you need, exactly when you need it.',
    },
    {
      name: 'Satisfaction Guarantee',
      description: 'Your satisfaction is our priority. If you’re not 100% happy with our service, we’ll make it right—no questions asked.',
    }
  ],
  services: {
    sectionTitle: "Our Services",
    description: "We offer a range of flexible, reliable cleaning and HVAC services designed to fit your lifestyle and preferences. Whether you're on-site or prefer us to handle things while you're away, we treat every space with the utmost care and respect. Many of our clients provide secure access, allowing us to work efficiently while they go about their day, but we're equally happy to work around you if you'd like to be present.",
    callouts: [
      {
        name: 'Cooling Services',
        description: 'Keep cool all season with our reliable cooling solutions, including AC installations, repairs, and maintenance to ensure efficient, effective performance for your home or business.',
        details: 'For residential clients, we offer routine cleaning services that include dusting, vacuuming, mopping, and tidying to maintain a fresh and inviting environment.',
        imageSrc: imageTwo,
        imageAlt: 'Comfortable and cool living room in a well-maintained residential home',
      },
      {
        name: 'Heating Services',
        description: 'Stay warm all winter with our heating solutions, including installation, repair, and maintenance to keep your space cozy and efficient.',
        details: 'We also offer deep cleaning for those extra-tough areas, perfect for seasonal refreshes or special events. Specialty services include carpet cleaning, window washing, and more.',
        imageSrc: imageThree,
        imageAlt: 'Cleaner providing thorough deep cleaning in a modern kitchen',
      },
      {
        name: 'Commercial & Residential HVAC Solutions',
        description: 'Our HVAC services meet both residential and commercial needs, offering expert installation, repair, and maintenance to keep your space comfortable and efficient year-round.',
        details: 'Our commercial cleaning services create a clean, hygienic environment for employees and clients alike, covering everything from workstations to common areas for a welcoming, professional atmosphere.',
        imageSrc: imageFour,
        imageAlt: 'Professional HVAC technician servicing a commercial building',
      }
    ],
    otherServices: [
      "Air Conditioning Installation & Repair",
      "Furnace Installation & Repair",
      "Duct Cleaning & Sealing",
      "Thermostat Installation",
      "Heat Pump Services",
    ]
  },
  about: {
    sectionTitle: "About Our Team",
    description: "We handle everything for you! The single biggest difference between us and other providers boils down to one simple premise: we care more. It’s the kind of caring that can only come from being the business owner. One that lives, works, and is a part of the community they serve. Through our services, we create a healthy space that you can take pride in.",
    image: {
      src: imageFive,
      alt: "S/A Cleaning LLC team providing residential cleaning services",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "Detroit, MI, United States",
    googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.440703997419!2d-83.04575348426102!3d42.33142787918952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824ca012e4f6e8b%3A0xfed50498e22f41bd!2sDetroit%2C%20MI%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { name: 'Apple Maps', url: 'https://maps.apple.com/?q=Detroit,%20MI' },
      { name: 'Bing Maps', url: 'https://www.bing.com/maps?q=Detroit,+MI' },
      { name: 'Google Earth', url: 'https://earth.google.com/web/search/Detroit,+MI,+USA/' },
      { name: 'Google Maps', url: 'https://goo.gl/maps/45Kd4LZy5JM2' }
    ]
  },
  contact: {
    sectionTitle: "Ready to Schedule Your Cleaning Service?",
    description: "Contact our management team to discuss your cleaning needs or to schedule a service. Whether it’s a one-time deep clean, routine maintenance, or a specialty service, we’re here to provide top-quality service every step of the way.",
    socialLinks: {
      facebook: socialMediaLink,     
      instagram: socialMediaLink,
      twitter: socialMediaLink,
      linkedin: socialMediaLink,
      youtube: socialMediaLink
    },
    contactNumbers: [
      "Mobile: (313) 854-3427",
    ],
    logo: bizIcon,
    emailRecipient: "WebDev@bizsolutions.us"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
